/* ThirdContainer.css */

.third-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  background-color: #ffffff;
  margin: 10px 10px 20px;
  padding-top: 2%;
}

.project-box {
  border: 1px solid #ddd;
  background: #fdfdfd;
  padding: 15px;
  width: calc(90% - 20px);
  margin: 10px 10px 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.previous-project {
  border: 1px solid #ddd;
  background: #fdfdfd;
  padding: 15px;
  width: calc(33.333% - 20px);
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  /* Stack children vertically */
  align-items: center;
  /* Center children horizontally */
  justify-content: flex-start;
  /* Align children to the start of the flex container */
  gap: 1rem;
  /* Adjust as necessary */
  overflow: hidden;
  /* Ensures that content does not overflow */
}

.responsive-carousel-image {
  max-width: 100%;
  /* Ensures the image is not larger than its container */
  max-height: 500px;
  /* Adjust the value according to what you deem fit for your design */
  height: auto;
  /* Preserve the aspect ratio of the image. */
  display: block;
  /* Ensures the image takes its own line within the carousel-item. */
  margin: auto;
  /* Centers the image in the carousel item if the width is less than 100%. */
}

/* Add this to your existing stylesheet */
.project-description-list {
  list-style-type: disc;
  /* Or you can choose another style such as 'circle' or 'square' */
  padding-left: 20px;
  /* Gives some space inside the list */
  margin-top: 20px;
  /* Additional space above the list */
  margin-bottom: 20px;
  /* Additional space below the list */
}

.project-description-list li {
  margin-bottom: 10px;
  /* Space between list items */
  font-size: 16px;
  /* Adjust the font size as needed */
  line-height: 1.6;
  /* Makes it more readable by increasing line height */
}

.project-description-list li strong {
  font-weight: bold;
  /* Increases the font-weight for the key */
  margin-right: 5px;
  /* Adds some space between the key and the colon */
}

.project-description-list li::before {
  content: '• ';
  /* Adds a bullet before each list item */
  color: #333;
  /* Bullet color, can match your design */
  font-size: 20px;
  /* Bullet size */
  margin-left: -20px;
  /* Aligns bullets properly with the text */
  position: absolute;
  /* Positions the bullet absolutely within the li element */
}

.project-title {
  font-size: 16px;
  /* Example size, adjust as needed */
  font-weight: bold;
  /* Make the title bold */
  color: #333;
  /* Dark color for the title */
  margin-top: 10px;
  /* Space between the image and the title */
  text-align: center;
  /* Center the title below the image */
}

.project-image {
  width: 100%;
  /* Ensures the image is contained within the parent */
  height: auto;
  /* Keeps the aspect ratio of the image */
  margin-bottom: 10px;
}

.small-description {
  font-size: 14px;
  color: #666;
  text-align: center;
  /* Center the text if needed */
  padding: 0 10px;
  /* Adds padding inside the description area */
}

.view-more-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: auto;
  /* Pushes the button to the bottom of the flex container */
}

.megsoft-product {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  flex-direction: row;
  background-color: red;
  color: white;
}

.megsoft-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.megsoft-product .laptop-image {
  flex-shrink: 0;
  margin-right: 2rem;
}

.megsoft-text {
  text-align: left;
  padding-left: 2rem;
  word-break: true;
}

.megsoft-text h1,
.megsoft-text .description-background p {
  word-break: break-word;
  hyphens: auto;
}

.megsoft-product h1,
.megsoft-product h2 {
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  text-shadow: 1px 1px 2px black;
}

.description-background {
  background-color: white;
  padding: 1rem;
  margin: 10px 0;
  border-radius: 10px;
  margin: 10px 0;
}

/* Set the bottom margin of the last elements to zero */
.project-box:last-child,
.description-background:last-child {
  margin-bottom: 0;
}

.megsoft-description {
  color: red;
  margin: 0;
}

.coming-soon {
  font-size: 20px;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

/* Updated styles for the Coming Soon button to match the provided styles */
.btn-2 {
  word-break: break-word;
  white-space: normal;
  /* Allows text to wrap instead of staying on a single line */
  overflow: hidden;
  /* Prevents content from overflowing */
  hyphens: auto;
  outline: none;
  display: block;
  width: 15vw;
  height: 5rem;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 0.3rem;
  box-shadow: 0 0.3rem 0.6rem #181135;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  font-size: 2vw;
  max-width: 90vw;
  background: var(--grad-liner);
  /* Fallback for browsers that do not support CSS variables */
  background: linear-gradient(86deg, #4776e6 0%, #8e54e9 100%);
}

.btn-2:hover,
.btn-2:focus-visible {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
}

.btn-2:active {
  box-shadow: rgba(0, 0, 0, 0.6) 0 2px 4px;
  transform: scale(0.96);
}

.section-heading {
  width: 100%;
  text-align: center;
  font-size: 2rem;
  /* Starting font size for larger screens */
  margin: 2rem 0 1.5rem;
  /* Add some space above and below the heading */
  color: #333;
  /* An example color - adjust as needed */
}

.laptop-image {
  /* Example styles, adjust as needed */
  width: 30vw;
  /* Alternatively, use a specific width like 'width: 300px;' */
  height: auto;
  /* Maintain aspect ratio */
  /* Further styling if required */
}



/* Media query adjustments */
@media (max-width: 1024px) {
  .project-box {
    width: calc(90% - 20px);
  }
}

@media (max-width: 768px) {
  .project-box {
    width: 100%;
    margin: 10px 0;
  }

  .section-heading {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  .megsoft-content,
  .megsoft-product {
    flex-direction: column;
    padding: 1rem;
  }

  .megsoft-product .laptop-image {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .megsoft-text {
    text-align: center;
    padding-left: 0;
    padding-top: 1rem;
  }

  .btn-2 {
    width: 50vw;
    /* Make the button width dependent on its content size */
    padding: 0.1rem 1rem;
    /* Adjust the padding if necessary */
    font-size: 5vw;
    /* Reduce the font size on smaller screens if needed */
    /* ... other responsive styles for .btn-2 ... */
  }

  .megsoft-text h1,
  .megsoft-text .description-background p {
    /* Apply the word break for smaller screens */
    word-break: break-word;
  }

  .megsoft-description {
    text-align: center;
  }

  .coming-soon {
    margin-top: 10px;
  }

  .laptop-image {
    /* Example styles, adjust as needed */
    width: 80vw;
    /* Alternatively, use a specific width like 'width: 300px;' */
    height: auto;
    /* Maintain aspect ratio */
    /* Further styling if required */
  }
}

/* Existing media query for mobile */
@media (max-width: 600px) {
  .previous-project {
    flex-basis: 100%;
    /* Take full width on smaller screens */
    margin: 10px 0;
    /* Adjust margin specifically for mobile */
  }
}

@media (max-width: 135px) {
  .section-heading {
    font-size: 15vw !important;
  }
}

/* Assuming --grad-liner is defined in a global stylesheet, otherwise define it here as well */
:root {
  --grad-liner: linear-gradient(86deg, #4776e6 0%, #8e54e9 100%);
}