.container {
  width: 90%;
  max-width: 800px;
  margin: auto;
  position: relative;
  aspect-ratio: 16 / 9;
}

.big-rectangle {
  width: 100%;
  aspect-ratio: 16 / 9;
  background-size: cover;
  background-position: center;
}

.overlaping-rectangle {
  width: 20vw;
  aspect-ratio: 1;
  height: auto;
  position: absolute;
  right: -10%;
  bottom: -10%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.overlaping-rectangle h2 {
  color: white;
  /* Above 972px, font-size is fixed */
  font-size: clamp(1rem, 2vw, 1.5rem);
}



.technologies-layout {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.technology-item {
  margin: 5px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
}

.technology-item span {
  font-size: clamp(0rem, 1rem, 1.5rem);
}

@media (max-width: 990px) {
  .big-rectangle {
    display: none;
    /* This will hide the big-rectangle when the viewport width is below 990px */
  }

  .overlaping-rectangle {
    position: static;
    /* Resets the position */
    width: 100%;
    /* Takes the full width of the container */
    right: 0;
    bottom: 0;
    margin-top: 20px;
    /* Optional: if you want some space between the rectangles */
  }

  /* If you want the the overlaping-rectangle to be the same aspect ratio as the big-rectangle */
  .overlaping-rectangle {
    aspect-ratio: 16 / 9;
    height: auto;
  }
}

/* @media (max-width: 972px) {
  .overlaping-rectangle {
    width: 45vw; 
  }
  
  .overlaping-rectangle h2 {
    font-size: calc(1.5rem * (100% / 972)); 
  }
  
  .technologies-layout, .technology-item {
    
    padding: calc(0.5rem * (100vw / 972)); 
  }

}  */