.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    color: black;
    z-index: 1000;
    padding: 0 1rem;
    box-sizing: border-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    /* Simple shadow for elegance */
}

.logo {
    display: flex;
    align-items: center;
    height: 60px;
}

.logo img {
    height: auto;
    max-height: 100%;
}

.nav-link {
    color: black;
    /* Set the text color to black */
    text-decoration: none;
    margin: 0 10px;
    /* Spacing between individual links */
    display: inline-block;
    padding: 8px 16px;
    /* Padding inside the link background */
    border-radius: 18px;
    /* Rounds the corners to a circular shape */
    background-color: white;
    /* Set the link background to white */
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s;
    /* Smooth transition for hover states */
}

.nav-link:hover,
.nav-link:focus {
    background-color: black !important;
    /* Black background on hover/focus */
    color: white !important;
    /* White text on hover/focus */
}

.navbar-menu {
    display: flex;
    align-items: center;
}

.navbar-toggle {
    display: none;
    align-items: center;
    justify-content: center;
    width: 48px;
    /* Width of the button */
    height: 48px;
    /* Height of the button */
    border: none;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
    transition: background-color 0.25s, color 0.25s;
    /* Smooth transition for background and icon color */
    /* Additional styles might be needed depending on your specific design requirements */
}

.navbar-toggle:focus {
    outline: none;
    /* Removes the outline to maintain the style on focus, you can replace with a custom style if needed */
}

.menu-icon {
    color: black;
    font-size: 1.5rem;
    /* Adjust icon size as needed */
}

.toggle-active,
.navbar-toggle:hover,
.navbar-toggle:focus {
    background-color: black;
    /* Background color for hover and active states */
}

.toggle-active .menu-icon,
.navbar-toggle:hover .menu-icon,
.navbar-toggle:focus .menu-icon {
    color: white;
    /* Icon color for hover and active states */
}

@media (max-width: 768px) {

    .navbar-menu,
    .navbar-menu.is-active {
        flex-direction: column;
        align-items: flex-end;
        /* This aligns the container's children to the right */
        position: fixed;
        top: 60px;
        /* Positioned right below the top bar */
        right: 0;
        max-height: 400px;
        background-color: rgba(0, 0, 0, 0.1);
        width: 100%;
        /* Full width */
        height: calc(100vh - 60px);
        /* Fills the remaining viewport height */
        overflow-y: scroll;
        transition: transform 0.3s ease;
        padding: 20px;
        box-shadow: -2px 0 4px rgba(0, 0, 0, 0.2);
        z-index: 1001;
        transform: translateX(100%);
        /* Starts off-screen */
    }

    .navbar-menu.is-active {
        transform: translateX(0);
        /* Animates into view */
    }

    .navbar-menu .nav-link {
        display: inline-block;
        /* Stack the links */
        text-align: right;
        /* Ensures text within the link is aligned to the right */
        margin: 10px 0;
        /* Vertical margin only */
        box-sizing: border-box;
        background-color: black;
        /* Background color for non-hover state */
        color: white;
        /* Text color for non-hover state */
    }

    .nav-link:hover,
    .nav-link:focus {
        background-color: white !important;
        /* Black background on hover/focus */
        color: black !important;
        /* White text on hover/focus */
    }

    .navbar-toggle {
        display: inline-flex;
        z-index: 1002;
    }
}