@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700');

* {
    font-family: 'Open Sans', sans-serif;
}

.footer-container {
    display: flex;
    flex-direction: column;
}

section {
    width: 100%;
    display: inline-block;
    background: #F8F8F8;
    /* Off-white background */
    height: 50vh;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    text-decoration: underline;
    color: #000000;
    /* Black text */
}

.footer-copyright {
    background: #6c757e;
    /* Off-white background */
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    font: bold 16px sans-serif;
    overflow: hidden;
}

.footer-copyright .copyright-text p {
    color: #F8F8F8;
    font-size: large;
}

.footer-distributed {
    margin-top: auto;
    background: #f5ffef;
    /* Off-white background */
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 55px 50px;
    overflow: hidden;
}

ul {
    list-style-type: none;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;

}

a {
    text-decoration: none !important;
    ;
    color: #000000
}

a:hover {
    color: white;
    text-decoration: none !important;
    cursor: pointer;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

.footer-distributed .footer-left {
    width: 35%;
}

.footer-distributed .footer-left .logo-text {
    font-family: Helvetica;
    font-size: 1.6rem;
    font-weight: 400;
}

.footer-distributed h3 {
    color: #000000;
    /* Black text */
    font: normal 36px 'Open Sans', cursive;
    margin: 0;
}

.footer-distributed h3 span {
    color: #4d4d4d;
    /* Darker grey for better contrast */
}

.footer-distributed .footer-links {
    color: #000000;
    /* Black text */
    margin: 20px 0 12px;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: #000000;
    /* Black text */
}

.footer-distributed .footer-company-name {
    color: #4d4d4d;
    /* Darker grey for better contrast */
    font-size: 14px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-center {
    width: 35%;
    vertical-align: middle;
}

.footer-distributed .footer-center div {
    margin-bottom: 15px;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #000000;
    /* Black text */
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p a {
    color: #4d4d4d;
    /* Darker grey for better contrast */
    text-decoration: none;
}

.footer-distributed .footer-links a:before {
    content: " ";
    font-weight: 300;
    font-size: 20px;
    left: 0;
    color: #000000;
    /* Black text for icon */
    display: inline-block;
    padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
    content: none;
}

.footer-distributed .footer-right {
    width: 20%;
}

.footer-distributed .footer-company-social {
    line-height: 20px;
    color: #000000;
    /* Black text */
    font-size: 16px;
    font-weight: normal;
    margin: 0;
}

.footer-distributed .footer-company-social span {
    display: block;
    color: #000000;
    /* Black text */
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
}

.footer-distributed .footer-icons {
    margin-top: 25px;
}

.footer-distributed .footer-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    cursor: pointer;
    background-color: #F8F8F8;
    /* Off-white background for icons */
    border-radius: 2px;
    font-size: 20px;
    color: #000000;
    /* Black icons */
    text-align: center;
    line-height: 35px;
    margin-right: 3px;
    margin-bottom: 5px;
}

.footer-logo {
    max-width: 100px;
    height: auto;
    transition: max-width 0.2s ease-out;
}

.footer-distributed:first-child {
    margin-top: 0;
}

@media (max-width:1250px){
    .footer-center p{
        font-size: 16px !important;
    }
}


@media (max-width: 880px) {
    .footer-distributed {
        font: bold 14px sans-serif;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }

    .footer-distributed .footer-company-name,
    .footer-distributed .footer-company-social,
    .footer-distributed .footer-center p,
    .footer-distributed .footer-links a,
    .footer-distributed .footer-company-social span {
        font-size: 12px !important;
    }

    .footer-center>div {
        display: flex;
        justify-content: center;
    }

    .footer-distributed h3 {
        font-size: 30px;
    }

    .footer-logo {
        width: 50%;
    }
}

@media (max-width: 270px) {
    .footer-distributed {
        padding: 20px 10px;
    }

    .footer-distributed .footer-left,
    .footer-distributed .footer-right,
    .footer-distributed .footer-center {
        width: auto;
    }

    .footer-distributed .footer-center p,
    .footer-distributed .footer-company-name,
    .footer-distributed .footer-links a,
    .footer-distributed .footer-company-social span {
        font-size: 10px !important;
    }

    .footer-distributed h3 {
        font-size: 20px;
    }

    .footer-logo {
        width: 80%;
        max-width: 150px;
    }
}

@media (max-width: 100px) {

    .footer-distributed,
    .footer-distributed .footer-left,
    .footer-distributed .footer-center p,
    .footer-distributed .footer-right,
    .footer-distributed .footer-company-name,
    .footer-distributed .footer-links a,
    .footer-distributed .footer-company-social,
    .footer-distributed .footer-icons a,
    .footer-distributed h3,
    .footer-distributed h3 span {
        font-size: 8px !important;
        line-height: 10px;
    }

    .footer-distributed .footer-center i,
    .footer-distributed .footer-icons a {
        width: 15px;
        height: 15px;
        line-height: 15px;
    }

    .footer-distributed .footer-center p,
    .footer-distributed .footer-links a {
        white-space: normal;
    }

    .footer-distributed .footer-company-name,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        overflow-x: auto;
    }
}